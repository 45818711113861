import {LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, ResponsiveContainer} from "recharts";
import Box from "@mui/material/Box";
import { CdrStatisticsOverTime } from "../../../../providers/dto/CdrStats";
import { E55CPalette } from "../../../data/constants/stats-charts-color";
import {Typography} from "@mui/material";
import {toEuro} from "../../../../helpers/helper";

interface CdrLineChartProps {
    sessionStatsOverTime: CdrStatisticsOverTime[] | undefined,
    chartColors: E55CPalette
}

export const CdrLineChart: React.FC<CdrLineChartProps> = ({sessionStatsOverTime, chartColors}) => {
    return (
        <Box sx={{display: {sm: "flex"}, paddingY: 3}}>
            <DisplayLineChart title={"CA"} dataKey={"cost_data"} sessionStatsOverTime={sessionStatsOverTime} chartColors={chartColors} isEuroValue={true}/>
            <DisplayLineChart title={"Sessions"} dataKey={"totalSession"} sessionStatsOverTime={sessionStatsOverTime} chartColors={chartColors}/>
        </Box>
    );
}

type DisplayLineChartParams = {
    title: string,
    dataKey: string,
    sessionStatsOverTime: CdrStatisticsOverTime[] | undefined,
    chartColors: E55CPalette,
    isEuroValue?: boolean
}

const valueDayFormatter = (val: number) => {
    const today = new Date();
    const dateTick = new Date(today);
    dateTick.setDate(today.getDate() + val);

    return `${dateTick.getDate()}/${dateTick.getMonth() + 1}`;
}

function DisplayLineChart({title, dataKey, sessionStatsOverTime, chartColors, isEuroValue = false}: DisplayLineChartParams) {
    return (
        <Box>
            <Box><Typography variant={'h6'} sx={{textAlign: "center"}}>{title}</Typography></Box>
            {sessionStatsOverTime?.length ?? 0 > 0 ? <ResponsiveContainer width={400} height={350}>
                <LineChart>
                    <Tooltip formatter={(value: number) => isEuroValue ? toEuro(value) : value} labelFormatter={valueDayFormatter}/>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis  dataKey="date_diff" allowDuplicatedCategory={false} tickFormatter={valueDayFormatter}/>
                    <YAxis  allowDataOverflow={true} />
                    {
                        sessionStatsOverTime?.map((el, index) => (
                            <Line dataKey={dataKey} data={el.data} name={el._id} key={el._id} stroke={chartColors.getColorForName(el._id)}/>
                        ))
                    }
                </LineChart>
            </ResponsiveContainer> : <Typography variant={'body1'} sx={{textAlign: "center"}}>Pas de données</Typography>}
        </Box>
    );
}
