import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { getDashboardDateOptionFromString, DashboardDateOption } from "../../../data/constants/dashboard-date-option";
import { DateRangePicker } from "../DateRangePicker";
import Box from "@mui/material/Box";
import { CdrStatistics } from "../../../../providers/dto/CdrStats";
import { E55CPalette } from "../../../data/constants/stats-charts-color";
import { useStore } from "react-admin";
import {Typography} from "@mui/material";
import {toEuro} from "../../../../helpers/helper";

interface TotalCostPieChartProps {
    sessionStats: CdrStatistics[] | undefined,
    chartColors: E55CPalette,
    selectedDate: DashboardDateOption,
    dateOptHandler : React.Dispatch<React.SetStateAction<DashboardDateOption>>
}

let isDarkTheme = false;

export const CdrPieChart: React.FC<TotalCostPieChartProps> = ({sessionStats, chartColors, dateOptHandler, selectedDate}) => {
    const[theme] = useStore("theme")
    isDarkTheme = theme === "dark"
    return (
        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
            <Box sx={{display: {sm: "flex"}, paddingY: 3}}>
                <DisplayPieChart title="CA" dataKey="cost_data" sessionStats={sessionStats} chartColors={chartColors} isEuroValue={true}></DisplayPieChart>
                <DisplayPieChart title="Sessions" dataKey="totalSession" sessionStats={sessionStats} chartColors={chartColors}></DisplayPieChart>
            </Box>
            <DateRangePicker
                value={selectedDate}
                handler={(e) => dateOptHandler(getDashboardDateOptionFromString(e.currentTarget.getAttribute("value")))}
                dateOptBag={[DashboardDateOption.TODAY, DashboardDateOption.SEVEN_DAYS, DashboardDateOption.THIRTY_DAYS, DashboardDateOption.ONE_HUNDRED_EIGHTY_DAYS, DashboardDateOption.YEAR_TO_DATE, DashboardDateOption.ONE_YEAR]}
            ></DateRangePicker>
        </Box>
    );
}

type DisplayPieChartParams = {
    title: string,
    dataKey: string,
    sessionStats: CdrStatistics[] | undefined,
    chartColors: E55CPalette,
    isEuroValue?: boolean
}

function DisplayPieChart({title, dataKey, sessionStats, chartColors, isEuroValue = false} : DisplayPieChartParams) : JSX.Element {
    return (
        <Box>
            <Box><Typography variant={'h6'} sx={{textAlign: "center"}}>{title}</Typography></Box>
            {sessionStats?.length ? <PieChart width={350} height={350}>
                <Tooltip formatter={(value: number) => isEuroValue ? toEuro(value) : value}/>
                {
                    <Pie data={sessionStats} dataKey={dataKey} nameKey="name" cornerRadius={5} innerRadius={10}
                    cx="50%" cy="50%" fill="#8884D8" label={centeredLabel} labelLine={false}>
                        {
                            sessionStats?.map((entry, index) => (
                                <Cell key={dataKey} fill={chartColors.getColorForName(entry.name)}/>
                        ))}
                    </Pie>
                }
            </PieChart> : <Typography variant={'body1'} sx={{textAlign: "center", p: 3}}>Pas de données</Typography>}
        </Box>
    );
}

const RADIAN = Math.PI / 180;
const centeredLabel = ({cx, cy, midAngle, innerRadius, outerRadius, name, percent, index}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill={isDarkTheme ? "white" : "black"} textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
      {`${name}`}
    </text>
  );
};
