import {
    AutocompleteInput,
    DatagridConfigurable,
    DateField, DateTimeInput, Empty, ExportButton,
    FilterButton, FunctionField,
    List, ReferenceInput,
    SelectColumnsButton,
    TextField, TextInput, TopToolbar, usePermissions,
    useRecordContext
} from "react-admin";
import WarningIcon from '@mui/icons-material/Warning';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

import React from "react";
import {ButtonTriggerSync} from "./widgets/ButtonTriggerSync";
import {AppPermissions, hasPermission} from "../../../helpers/permissions";
import {Cdr} from "../../data/cdr";
import SearchHintInput from "../../components/input/SearchHintInput";
import exporter from "./exporter";
import TotalCostField from "./widgets/TotalCostField";

const TagsField = ({label, source} : {label: string, source: string}) => {
    const record = useRecordContext();

    if (!record || !record[source]) {
        return null;
    }

    return (
        <Box>
            {record[source].map((item: string) => (
                <Chip key={item} label={item} sx={{backgroundColor: "red", color: "white"}}/>
            ))}
        </Box>
    )
};

const listFilters = [
    <SearchHintInput source="q" alwaysOn key={'search'} hint={"Rechercher une chaine de caracteres parmi : id, party_id, auth_id, authorization_id, location.id, location.evses.evse_id."} />,
    <ReferenceInput reference="exploitationEmsps"  source="party_id" label={'Emsp'}>
        <AutocompleteInput optionText="party_id" optionValue="party_id" label={'Emsp'}/>
    </ReferenceInput>,
    <TextInput source="issuer" label="Issuer (exact)" key={'issuer'} resettable/>,
    <TextInput source="session_id" label="session id" key={'session_id'} />,
    <TextInput source="auth_id" label="auth id" key={'auth_id'} />,
    <TextInput source="authorization_id" label="authorization_id" key={'authorization_id'} />,
    <TextInput source="location_id" label="location id" key={'location_id'} />,
    <TextInput source="evse_id" label="evse id" key={'evse_id'} />,
    <DateTimeInput source="start_from" key="start_from" label="Commencé après"/>,
    <DateTimeInput source="start_to" key="start_to" label="Commencé avant"/>,
    <DateTimeInput source="stop_from" key="start_from" label="Fini après"/>,
    <DateTimeInput source="stop_to" key="start_to" label="Fini avant"/>,
    <TextInput source="invoice_id" label="Invoice id" key={'invoice'} resettable/>,
];

const CdrListActions = () => {
    const { permissions } = usePermissions();

    return (
        <TopToolbar>
            <SelectColumnsButton/>
            <FilterButton/>
            <ExportButton maxResults={10000}/>
            {hasPermission(AppPermissions.CdrsSync, permissions) && <ButtonTriggerSync/>}
        </TopToolbar>
    );
};

const EmptyCdrs = () => {
    const { permissions } = usePermissions();
    return (
        <Box textAlign="center" m={1} sx={{marginLeft: "auto", marginRight: "auto"}}>
            <Empty/>
            {hasPermission(AppPermissions.CdrsSync, permissions) && <ButtonTriggerSync/>}
        </Box>
    );
};

const cdrRowSx = (record: Cdr, index: number) => ({
    backgroundColor: record.cdr.total_cost < 0
        || !httpResponseOk(record)
        || (record.ocpiResponseCode && record.ocpiResponseCode !== 1000)
        ? 'error.main' : 'inherit',
});

const WarningWrapper = ({showWarning, children} : {showWarning: boolean, children: React.ReactNode}) => {
    return (showWarning ?
        <Box sx={{display: "flex", alignItems: "center", gap: 2}}><Box>{children}</Box><Box><WarningIcon height={12} /></Box></Box> : <Box>{children}</Box>);
}

const httpResponseOk = (record: Cdr) : boolean => {
    if (!record.httpResponse) {
        return true;
    }

    return ["200 OK", "201 CREATED"].includes(record.httpResponse.toUpperCase());
}

export const CdrList = () => {

    return (<List filters={listFilters}
                  actions={<CdrListActions/>}
                  sort={{field: "cdr.start_date_time", order: "DESC"}}
                  exporter={exporter}
                  empty={<EmptyCdrs />}
            >
            <DatagridConfigurable rowClick="edit" bulkActionButtons={false} rowSx={cdrRowSx}
                                  omit={[
                                      'cdr.auth_method',
                                      'cdr.authorization_id',
                                      'cdr.tariffs',
                                      'cdr.stop_date_time',
                                      'cdr.location.evses',
                                      'cdr.total_energy',
                                      'cdr.total_time',
                                      'cdr.total_parking_time',
                                      'cdr.last_updated',
                                      'notes',
                                      'invoice',
                                      'ocppStopReason',
                                      'ocppStopActor',
                                      'httpResponse',
                                      'ocpiResponseCode',
                                      'ocpiResponseMessage',
                                      'ocpiResponseURL',
                                  ]}>
                <TextField source="party_id" label={"Emsp"}/>
                <TextField source="issuer"/>
                <TextField source="uid"/>
                <DateField source="cdr.start_date_time" showTime={true} locales={'fr-FR'} />
                <DateField source="cdr.stop_date_time" showTime={true} locales={'fr-FR'} />
                <TextField source="cdr.auth_id" label={'auth_id'}/>
                <TextField source="cdr.authorization_id" label={'authorization_id'}/>
                <TextField source="cdr.auth_method" label={'auth_method'}/>
                <FunctionField source="cdr.tariffs" label="Tariff" render={(record: Cdr) => {
                    return record.cdr.tariffs?.length
                        ? record.cdr.tariffs[0].id
                        : null
                }}/>
                <TextField source="cdr.location.id" label={'Location id'}/>
                <TextField source="cdr.location.name" label={'Location'}/>
                <TextField source="cdr.location.country" label={'country'}/>
                <FunctionField source="cdr.location.evses" label="Evse" render={(record: Cdr) => {
                    return record.cdr.location.evses
                        ? <Box>{record.cdr.location.evses.map((val: any) => val.evse_id).join('<br/>')}</Box>
                        : null
                }}/>
                <TotalCostField source="cdr.total_cost" />
                <TextField source="cdr.total_energy" />
                <TextField source="cdr.total_time"/>
                <TextField source="cdr.total_parking_time"/>
                <DateField source="cdr.last_updated" showTime={true} locales={'fr-FR'} />
                <TextField source="ocppStopReason" label={'ocppStopReason'}/>
                <TextField source="ocppStopActor" label={'ocppStopActor'}/>
                <FunctionField source="httpResponse" label="httpResponse" render={(record: Cdr) =>
                    <WarningWrapper showWarning={!httpResponseOk(record)}>{record.httpResponse}</WarningWrapper>}
                />
                <FunctionField source="ocpiResponseCode" label="ocpiResponseCode" render={(record: Cdr) =>
                    <WarningWrapper showWarning={record.ocpiResponseCode !== undefined && record.ocpiResponseCode !== 1000}>{record.ocpiResponseCode}</WarningWrapper>}
                />
                <TextField source="ocpiResponseMessage" label={'ocpiResponseMessage'}/>
                <TextField source="ocpiResponseURL" label={'ocpiResponseURL'}/>
                <TextField source="notes"/>
                <TagsField source="tags" label={"tags"}/>
                <FunctionField source="invoice" label="Facturation" render={(record: Cdr) => {
                    return record.invoice
                        ? `${record.invoice.year}-${record.invoice.month + 1}`
                        : null
                }}/>
                <TextField source="id" label={"Session id"}/>
            </DatagridConfigurable>
        </List>
    );
};
