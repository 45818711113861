import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'; 
import { toUnit } from '../../../../helpers/helper';
import { CdrStatistics } from '../../../../providers/dto/CdrStats';
import Paper from '@mui/material/Paper';
import { CdrDashboardType } from '../../../data/constants/cdr-dashboard-type';
import { E55CPalette } from '../../../data/constants/stats-charts-color';

interface CdrDashboardProps {
    dashboardType: CdrDashboardType,
    sessionStats: CdrStatistics[] | undefined,
    chartColors: E55CPalette
}

export const CdrDashboardTable: React.FC<CdrDashboardProps> = ({dashboardType, sessionStats, chartColors}) => {
    return (
        <TableContainer sx={{ minWidth : 320, width: '85vw'}} component={Paper}>
            <Table size="medium" aria-label="Session cost table">
                <TableHead>
                    <TableRow>
                        <TableCell>{dashboardType}</TableCell>
                        <TableCell >CA. total</TableCell>
                        <TableCell >% du CA. total</TableCell>
                        <TableCell >Nb Sessions</TableCell>
                        <TableCell >% des sessions totales</TableCell>
                        <TableCell >CA. moyen par session</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sessionStats?.map((stat, index) => (
                        <TableRow
                            key={stat.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>
                                { getEMSPLegendColorElement(chartColors.getColorForName(stat.name)) } {stat.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                { toUnit(stat.cost_data, '€') }
                            </TableCell>
                            <TableCell>
                                { percentageOf(stat, "cost_data", sessionStats) }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                { stat.totalSession }
                            </TableCell>
                            <TableCell>
                                { percentageOf(stat, "totalSession", sessionStats) }
                            </TableCell>
                            <TableCell>
                                { toUnit(stat.cost_data / stat.totalSession, '€') }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function percentageOf(dataLine: CdrStatistics, dataKey: keyof CdrStatistics, dataSet: CdrStatistics[]) : string {
    return toUnit(
        (dataLine[dataKey] as number * 100) / getTotal(dataSet, dataKey),
        "%"
    )
}

function getEMSPLegendColorElement(color: string) : JSX.Element {
    return(
        <svg width="25 " height="25" xmlns="http://www.w3.org/2000/svg">
            <rect width="10" height="10" x="10" y="10" rx="10" ry="10" fill={color} />
        </svg>
    )
}

function getTotal(stats: CdrStatistics[] | undefined, dataKey: keyof CdrStatistics): number {
    return stats?.reduce((acc, val) => acc += val[dataKey] as number, 0) ?? 0;
} 