export enum AppPermissions {
    CdrsView = "cdrs-view",
    CdrsEdit = "cdrs-edit",
    CdrsSync = "cdrs-sync",
    CdrOverwriteCost = "cdrs-overwrite-cost",
    TariffsManage = "tariffs-manage",
    TariffLinkManage = "tarifflinks-manage",
    BadgesManage = "badges-manage",
    ExploitationManage = "exploitation-manage",
}

interface IGroupPermissions {
    [index: string]: string[]
}

const groupsPermissions : IGroupPermissions = {
    "exploitation-user": [
        AppPermissions.CdrsView,
        AppPermissions.CdrsEdit,
    ],

    "exploitation-admin": [
        AppPermissions.CdrsView,
        AppPermissions.CdrsEdit,
        AppPermissions.CdrsSync,
        AppPermissions.CdrOverwriteCost,
        AppPermissions.TariffLinkManage,
    ],

    "ops-user": [
        AppPermissions.CdrsView,
        AppPermissions.CdrsEdit,
    ],

    "ops-admin": [
        AppPermissions.CdrsView,
        AppPermissions.CdrsEdit,
        AppPermissions.CdrsSync,
        AppPermissions.CdrOverwriteCost,
        AppPermissions.TariffsManage,
        AppPermissions.TariffLinkManage,
        AppPermissions.ExploitationManage,
    ],

    "ops-superadmin": [
    ],
}

export const hasPermission = (permission: string, userGroups: string[]) : boolean => {
    if (!userGroups) {
        return false;
    }

    if (userGroups.includes("ops-superadmin")) {
        return true;
    }

    for (let group of userGroups) {
        if (group in groupsPermissions && groupsPermissions[group].includes(permission)) {
            return true;
        }
    }

    return false;
}
