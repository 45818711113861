import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { CdrDashboardType } from "../../../data/constants/cdr-dashboard-type";
import CdrDashboardDetails from "./CdrDashboardDetails";
import {useState} from "react";
import Box from "@mui/material/Box";
import DashboardSection from "../DashboardSection";

const CdrSection = () => {
    const[dashboardType, setDashboardType] = useState<CdrDashboardType>(CdrDashboardType.PARTY);

    return(
        <DashboardSection title={'Charges'}>
            <Box maxWidth="85vw" width="100%" sx={{display: "flex", flexDirection: {xs: "column"}, gap: 3, alignItems: "center", overflowX: "hidden"}}>
                <ToggleButtonGroup value={dashboardType} color={'primary'} sx={{marginY: 3}} exclusive onChange={(event, newValue) => setDashboardType(newValue)}>
                    <ToggleButton value={CdrDashboardType.PARTY} aria-label="Party" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                        Par eMSP
                    </ToggleButton>
                    <ToggleButton value={CdrDashboardType.ISSUERS} aria-label="Issuer" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                        Par issuer
                    </ToggleButton>
                </ToggleButtonGroup>
                <CdrDashboardDetails dashboardType={dashboardType}></CdrDashboardDetails>
            </Box>
        </DashboardSection>
    );
}

export default CdrSection;
