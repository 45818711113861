import {StringMap} from "ra-core/src/i18n/TranslationMessages";

const frMessages: StringMap = {
  country: "pays",
  badge: {
    error: {
      uid_is_not_unique: "Cet uid est déjà utilisé",
    },
  },
  location: {
    column: {
      opendata: {
        enable_sync: 'Sync opendata'
      }
    },
    opendata: {
      intro: 'Spécifiez ici les valeurs de l\'export opendata non déductibles des infos de la location',
      field: {
        id_station_itinerance: {
          label: 'id station itinerance',
        },
        implantation_station: {
          label: 'implantation station',
        },
        adresse_station: {
          label: 'adresse station',
        },
        gratuit: {
          label: 'gratuit',
        },
        paiement_acte: {
          label: 'paiement acte',
        },
        paiement_cb: {
          label: 'paiement cb',
        },
        paiement_autre: {
          label: 'paiement autre',
        },
        tarification: {
          label: 'tarification',
        },
        condition_acces: {
          label: 'condition d\'accès',
        },
        reservation: {
          label: 'reservation',
        },
        horaires: {
          label: 'horaires',
        },
        accessibilite_pmr: {
          label: 'accessibilite pmr',
        },
        restriction_gabarit: {
          label: 'restriction de gabarit',
        },
        station_deux_roues: {
          label: 'station deux roues',
        },
        raccordement: {
          label: 'raccordement',
        },
        date_mise_en_service: {
          label: 'date de mise en service',
        },
        observations: {
          label: 'observations',
        },
        code_insee_commune: {
          label: 'Code insee commune',
          not_found: 'Code insee de la commune non trouvé pour cette adresse'
        },
        enable_sync: {
          label: 'Integrer dans l\'export opendata'
        },
      }
    }
  },
  error: {
    form: {
      validation: "Le formulaire n'est pas valide",
    },
  },
  resources: {
    cdrs: {
      fields: {
        uid: "Badge",
        cdr: {
          start_date_time: "Début de charge",
          stop_date_time: "Fin de charge",
          total_cost: "Coût",
          total_energy: "Énergie",
          total_time: "Temps",
          total_parking_time: "Parking",
          last_updated: "last_updated",
        }
      },
      overrides: {
        cta: "Mettre à jour le coût",
        success: "Le coût a été mis à jour",
        dialog: {
          title: "Mettre à jour le coût",
          text: "Mettre à jour le coût",
        },
        card: {
          title: "Historique des modifications de coûts",
          table: {
            changes: "Modification(s)",
            updatedAt: "Mis à jour le",
            updatedBy: "Mis à jour par",
          }
        }
      },
      cta: {
        update_cost: "Mettre à jour le coût",
      }
    },
    "exploitation-locations": {
      fields: {
        type: "Type",
        "metaData.activationDate": "Date de mise en service",
      }
    },
    tariffgroups: {
      name: "Groupe de tarifs |||| Groupes de tarifs",
      cta: {
        add_subtariff: "Ajouter un tarif",
        remove_subtariff: "Supprimer ce tarif",
        add_element: "Ajouter un élément",
        remove_element: "Supprimer cet élément",
        add_price_component: "Ajouter un composant de prix",
        remove_price_component: "Supprimer ce composant",
      },
      fields: {
        tariffgroup_id: "ID Groupe",
        subtariffs: "Tarifs",
        "subtariffs.tariffkey": "Clé",
        "subtariffs.tariff.id": "Id tarif",
        "subtariffs.tariff.currency": "Devise",
        "subtariffs.tariff.elements.price_components.price": "Prix",
        "subtariffs.tariff.elements.price_components.step_size": "Incrément",
        "subtariffs.tariff.elements.price_components.type": "Type",
      },
      labels: {
        subtariffs: "Tarifs",
        elements: "Éléments"
      }
    }
  },
  cta: {
    cancel: "Annuler",
  },
  exploitation: {
    stats: {
      deployment: {
        section: 'Déploiement',
        signed: 'Points de charge signés',
        deployed: 'Points de charge déployés',
        removed: 'Points de charge retirés',
      },
      locations: {
        section: 'Statistiques des locations',
        totalLocation: 'Total locations',
        totalEVSE: 'Total EVSEs',
        totalAvailable: 'EVSEs disponibles',
        totalRemoved: 'Retirées',
        totalPlanned: 'Planifiées',
      },
    },
    admin: {
      config: {
        fields: {
          recipients: 'Destinataires',
          email: 'Email',
          from: 'Expéditeur',
          prefix: 'Préfixe',
        }
      },
      resendStatsMailing: {
        cta: 'Renvoyer les stats',
        success: 'Le mail de stats a été renvoyé',
        dialog: {
          title: 'Renvoyer les stats',
          text: 'Renvoyer les stats à tous les destinataires. Vous pouvez si vous le désirez modifier le sujet du mail.',
          subject: 'Sujet (optionel',
          send: 'Envoyer',
        }
      },
      sendMailStatTest: {
        cta: 'Envoyer un mail test',
        success: 'Le mail de test a été envoyé',
        dialog: {
          title: 'Envoyer un mail de test',
          text: 'Envoyer un mail de test à une liste restreinte de destinataires',
          recipients: 'Destinataires',
          send: 'Envoyer',
        }
      },
    }
  },
  menu: {
    items: {
      exploitation: {
        submenu: {
          admin: "Admin",
        }
      },
      statistics: {
        label: "Statistiques",
        submenu: {
          cdrs: "CDRs",
          deployment: "Déploiement",
          invoicing: "Pré-facturation",
          locations: "Locations",
        }
      }
    }
  },
};

export default frMessages;
