import iwanthue, { ColorSpace } from "iwanthue";
import Palette, { PaletteGenerateFromValuesSettings } from "iwanthue/palette"

export class E55CPalette {
    palette : Palette<string>;

    constructor(domain: string[] = [""], isDarkTheme: boolean = false, colorblind: boolean = false) {
        this.palette = generatePalette(domain, isDarkTheme, colorblind);
    }

    getColorForName(name: string): string {
        return this.palette.get(name);
    }

    refresh(domain: string[] = [""], isDarkTheme: boolean, colorblind: boolean = false) {
        this.palette = generatePalette(domain, isDarkTheme, colorblind);
    }
}


export function generateColors(numColors: number): string[] {
    return iwanthue(numColors <= 0 ? 1 : numColors);
}

function generatePalette(domain: string[], isDarkTheme: boolean, colorblind: boolean = false): Palette<string> {
    return Palette.generateFromValues(
        "nameToColors",
        domain,
        getHueParameters(colorblind, isDarkTheme)
    )
}

function getColorForTheme(isDark: boolean): ColorSpace {
    return isDark ? "fancy-dark" : "fancy-light";
}

function getHueParameters(colorblind: boolean, isDarkTheme : boolean): PaletteGenerateFromValuesSettings {
    return {
        defaultColor: "red",
        colorSpace: colorblind ? "colorblind" : getColorForTheme(isDarkTheme),
        quality: 100,
        clustering: "k-means",
        distance: colorblind ? "compromise" : "euclidean"
    }
}
