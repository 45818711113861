import {Menu, usePermissions} from "react-admin";
import {AppPermissions, hasPermission} from "../../helpers/permissions";
import appConfig from "../../config";
import Submenu from "./Submenu";
import {
    CdrIcon, DeployIcon,
    ExploitationIcon,
    FacturationIcon,
    LocationIcon,
    QueryStatsIcon,
    TariffsIcon
} from "../constants";
import InvoicingIcon from "@mui/icons-material/Euro";
import SettingsIcon from "@mui/icons-material/Settings";
import ExploitationAdminPage from "../pages/exploitation/ExploitationAdminPage";

const tariffsEnabled = appConfig.TARIFFS_ENABLED;
const xlsxEnabled = appConfig.XLSX_ENABLED;

export default function AppMenu() {
    const { permissions } = usePermissions();
    const showMenuTariffs = tariffsEnabled && (hasPermission(AppPermissions.TariffsManage, permissions)
        || hasPermission(AppPermissions.TariffLinkManage, permissions));
    const showMenuExploitation = hasPermission(AppPermissions.CdrsView, permissions)
        || hasPermission(AppPermissions.BadgesManage, permissions)
        || hasPermission(AppPermissions.CdrsSync, permissions);
    const showMenuFacturation = appConfig.FEATURE_SHOW_INVOICING;
    const showMenuStatistics = appConfig.FEATURE_SHOW_STATS;
    const showMenuDeployStatistics: boolean = xlsxEnabled;

    return <Menu>
        <Menu.DashboardItem primaryText={'Tableau de bord'}/>
        {showMenuTariffs &&
            <Submenu text={'Tariffs'} icon={<TariffsIcon />}>
                {tariffsEnabled && hasPermission(AppPermissions.TariffsManage, permissions) ? <Menu.ResourceItem name="tariffgroups" /> : <></>}
                {tariffsEnabled && hasPermission(AppPermissions.TariffLinkManage, permissions) ? <Menu.ResourceItem name="tarifflinks" /> : <></>}
            </Submenu>}
        {showMenuExploitation &&
            <Submenu text={'Exploitation'} icon={<ExploitationIcon />}>
                {hasPermission(AppPermissions.CdrsView, permissions) && <Menu.ResourceItem name="cdrs" />}
                {hasPermission(AppPermissions.CdrsSync, permissions) && <Menu.ResourceItem name="cdr-import-jobs" />}
                {hasPermission(AppPermissions.BadgesManage, permissions) && <Menu.ResourceItem name="exploitation-locations" />}
                {appConfig.FEATURE_EXPLOITATION_STATS_MAILING && (AppPermissions.ExploitationManage, permissions) && <Menu.Item to={ExploitationAdminPage.route} primaryText={'menu.items.exploitation.submenu.admin'} leftIcon={<SettingsIcon/>}/>}
            </Submenu>}
        {showMenuFacturation &&
            <Submenu text={'Facturation'} icon={<FacturationIcon />}>
                {<Menu.ResourceItem name="invoicingIssuers" />}
            </Submenu>}
        {showMenuStatistics &&
            <Submenu text={'menu.items.statistics.label'} icon={<QueryStatsIcon />}>
                {<Menu.Item to={'statistics/locations'} primaryText={'menu.items.statistics.submenu.locations'} leftIcon={<LocationIcon/>}/>}
                {<Menu.Item to={'statistics/cdrs'} primaryText={'menu.items.statistics.submenu.cdrs'} leftIcon={<CdrIcon/>}/>}
                {showMenuDeployStatistics && <Menu.Item to={'statistics/deployment'} primaryText={'menu.items.statistics.submenu.deployment'} leftIcon={<DeployIcon/>}/>}
                {hasPermission(AppPermissions.CdrsView, permissions) && <Menu.Item to={'statistics/invoicing'} primaryText={'menu.items.statistics.submenu.invoicing'} leftIcon={<InvoicingIcon/>}/>}
            </Submenu>
        }
    </Menu>
}
