import React from "react";
import {
    ArrayInput,
    FormDataConsumer,
    NumberInput,
    required,
    SelectInput,
    SimpleFormIterator,
    TextInput, useRecordContext,
    useTranslate
} from "react-admin";
import TariffKey from "../../components/input/TariffKey";
import CurrencyInput from "../../components/input/CurrencyInput";
import { alpha } from '@mui/material';
import {colors} from "../../constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Stack } from '@mui/material';
import Typography from "@mui/material/Typography";
import {AddRowButton} from "../../components/input/AddRowButton";
import {RemoveRowButton} from "../../components/input/RemoveRowButton";

export const Tarifffields = () => {
    const translate = useTranslate();
    const record = useRecordContext();

    return <>
        <TextInput source="tariffgroup_id" validate={required()} readOnly={!!record?.id}/>
        <Typography variant="h6">{translate('resources.tariffgroups.labels.subtariffs')}</Typography>
        <SubTariffsInput />
    </>
}

const SubTariffsInput = () => {
    const translate = useTranslate();

    return (<ArrayInput source="subtariffs" name={"subtariffs"} label={false}>
        <SimpleFormIterator
            addButton={<AddRowButton variant={'outlined'} label={'resources.tariffgroups.cta.add_subtariff'}></AddRowButton>}
            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_subtariff'} />}
            disableClear
        >
            <FormDataConsumer>
                {({
                      scopedFormData, // The data for this item of the ArrayInput
                      })=> {
                        return <Accordion sx={{marginY: 1}}>
                            <AccordionSummary expandIcon={<ArrowDownwardIcon/>}
                                              aria-controls="panel1-content"
                                              id="panel1-header">{scopedFormData?.tariff?.id ?? 'NEW'}</AccordionSummary>
                            <AccordionDetails>
                                <Stack alignItems="flex-start">
                                    <TariffKey source={"tariffkey"} validate={required()}/>
                                    <TextInput source={"tariff.id"} validate={required()}/>
                                    <CurrencyInput source={"tariff.currency"} validate={required()} defaultValue={"EUR"}/>
                                    <Typography variant="h6">{translate('resources.tariffgroups.labels.elements')}</Typography>
                                    <TariffElements source={"tariff.elements"}
                                                    disableAdd={(scopedFormData?.tariff?.elements?.length ?? 0) > 0}/>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>;
                    }
                }
            </FormDataConsumer>
        </SimpleFormIterator>
    </ArrayInput>);
}

const TariffElements = ({source, disableAdd} : {source: string, disableAdd: boolean}) => {
    return (<ArrayInput source={source} label={false} >
        <SimpleFormIterator
            disableAdd={disableAdd}
            addButton={<AddRowButton variant={'outlined'} label={'resources.tariffgroups.cta.add_element'} />}
            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_element'} />}
            disableClear
            sx={{
                '.RaSimpleFormIterator-line': {
                    paddingLeft: '8px',
                    marginY: '8px',
                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                }
            }}>
            <PriceComponentsInput source="price_components" />
        </SimpleFormIterator>
    </ArrayInput>);
}


const PriceComponentsInput = ({source} : {source: string}) => {
    return (<ArrayInput source={source} label={false}>
        <SimpleFormIterator inline
                            addButton={<AddRowButton label={'resources.tariffgroups.cta.add_price_component'}></AddRowButton>}
                            removeButton={<RemoveRowButton label={'resources.tariffgroups.cta.remove_price_component'} />}
                            disableClear
                            sx={{
                                '.RaSimpleFormIterator-line': {
                                    paddingLeft: '8px',
                                    backgroundColor: alpha(colors.e55cLightBlue, 0.1)
                                }
                            }}>
            <NumberInput source="price" validate={required()} />
            <NumberInput source="step_size" validate={required()} step={1}/>
            <SelectInput source="type" validate={required()} choices={[{id: "TIME", name: "TIME"}, {id: "PARKING_TIME", name: "PARKING_TIME"}, {id: "ENERGY", name: "ENERGY"}, {id: "FLAT", name: "FLAT"}]} sx={{marginTop: 0}}/>
        </SimpleFormIterator>
    </ArrayInput>)
}
