import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactNode } from 'react';

interface RemoteContentDisplayProps {
    isLoading: boolean;
    dataArray: any[] | undefined;
    children: ReactNode;
}

// WHAT DO
// LOADING STATUS : SPINNER / NOT SPINNER
// DATA STATUS : NO DATA IN ARRAY OR
// DISPLAY CHILD ELEMENT

// TODO : replace DashboardSection ???
export const RemoteContentDisplay: React.FC<RemoteContentDisplayProps> = ({isLoading, dataArray, children}) => {
    if (isLoading) {
        return (
            <Backdrop open={true} sx={{ position: "relative"}}>
                <CircularProgress></CircularProgress>
            </Backdrop>
        )
    } else {
      return <>{children}</>;
    }
}
