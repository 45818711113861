import { MouseEventHandler } from 'react';
import { DashboardDateOption, translateDateOption } from '../../data/constants/dashboard-date-option';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

interface DateRangePickerProps {
    value: string,
    handler : MouseEventHandler<Element>,
    dateOptBag: DashboardDateOption[]
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({value, handler, dateOptBag}) => {
    return(
        <ToggleButtonGroup value={value}>
            {
                dateOptBag.map((el) => (
                    <ToggleButton key={translateDateOption(el)} value={el} onClick={handler}>{translateDateOption(el)}</ToggleButton>
                ))
            }
        </ToggleButtonGroup>
    );
}
