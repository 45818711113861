import * as React from "react";
import Paper from '@mui/material/Paper';
import {Title} from 'react-admin';
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import LocationsSection from "./dashboard/LocationsSection";
import DeploymentSection from "./dashboard/DeploymentSection";
import CdrSection from "./dashboard/session/CdrSection";
import appConfig from "../../config";

const HeroCard = () => (<Paper sx={{
    padding: 2,
    marginY: 2,
    color: (theme) => theme.palette.getContrastText(theme.palette.primary.dark),
    background: (theme) => `linear-gradient(to right bottom, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
    display: 'flex',
    justifyContent: 'space-between'
}}>
    <Box>
        <Box>
            <Typography variant="h2" sx={{fontSize: '1.5em'}}>Angus - Tableau de bord</Typography>
        </Box>
        <Box>Bienvenue sur votre interface de gestion CPO</Box>
    </Box>
</Paper>);

const Dashboard = () => {
    return (<>
            <Title title="Tableau de bord"/>
            <HeroCard/>
            {appConfig.FEATURE_SHOW_STATS && <>
                {appConfig.XLSX_ENABLED && <DeploymentSection></DeploymentSection>}
                <CdrSection></CdrSection>
                <LocationsSection></LocationsSection>
            </>}
        </>
    );
};

export default Dashboard;
