import {
    Edit, SimpleForm,
    SelectArrayInput,
    TextInput,
    useRecordContext,
    ShowBase, ListButton, usePermissions,
} from 'react-admin';

import Typography from '@mui/material/Typography';

import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {Cdr} from "../../data/cdr";
import {tokWh, toUnit} from "../../../helpers/helper";
import {ChevronLeft} from "@mui/icons-material";
import {ButtonOverrideCost} from "./widgets/ButtonOverrideCost";
import CdrOverridesHistory from "./widgets/CdrOverridesHistory";
import TotalCostField from "./widgets/TotalCostField";
import appConfig from "../../../config";
import {AppPermissions, hasPermission} from "../../../helpers/permissions";

type AsideFieldProps = {
    label: string,
    children: any
}

const formatDate = (val: string) : string => {
    const date = new Date(val);

    return new Intl.DateTimeFormat('fr-FR',  {dateStyle: 'short', timeStyle: 'medium'}).format(date)
}

const DetailsField = ({label, children}: AsideFieldProps) => {
    return <Box sx={{paddingY: "5px"}}>
        <Box sx={{fontWeight: "bold"}}>{label}</Box><Box>{children}</Box>
    </Box>
}

const Details = () => {
    const record = useRecordContext<Cdr>();

    if (!record) {
        return <></>;
    }

    return (
        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}} gap={2}>
            <Card sx={{flexGrow: 1}}>
                <CardContent>
                    <Typography variant="h6">Détails</Typography>
                    <Box sx={{display: "flex", flexWrap: "wrap", justifyContent: "space-between"}} gap={3}>
                        <Box>
                            <DetailsField label={'Date de début'}>
                                {formatDate(record.cdr.start_date_time)}
                            </DetailsField>
                            <DetailsField label={'Date de fin'}>
                                {formatDate(record.cdr.stop_date_time)}
                            </DetailsField>
                            <DetailsField label={'Date de mise à jour'}>
                                {formatDate(record.cdr.last_updated)}
                            </DetailsField>
                        </Box>
                        <Box>
                            <DetailsField label={'auth_id'}>
                                {record.cdr.auth_id}
                            </DetailsField>
                            <DetailsField label={'authorization_id'}>
                                {record.cdr.authorization_id || '-'}
                            </DetailsField>
                            <DetailsField label={'auth_method'}>
                                {record.cdr.auth_method}
                            </DetailsField>
                        </Box>
                        <Box>
                            <DetailsField label={'location id'}>
                                {record.cdr.location.id}
                            </DetailsField>
                            <DetailsField label={'location name'}>
                                {record.cdr.location.name}
                            </DetailsField>
                            <DetailsField label={'location country'}>
                                {record.cdr.location.country}
                            </DetailsField>
                            <DetailsField label={'location evse id'}>
                                {record.cdr.location.evses[0]?.evse_id ?? '-'}
                            </DetailsField>
                            <DetailsField label={'location evse uid'}>
                                {record.cdr.location.evses[0]?.uid ?? '-'}
                            </DetailsField>
                        </Box>
                        <Box>
                            <DetailsField label={'Connector id'}>
                                {record.cdr.location.evses[0]?.connectors[0].id ?? '-'}
                            </DetailsField>
                            <DetailsField label={'Connector Stardard'}>
                                {record.cdr.location.evses[0]?.connectors[0]?.standard ?? '-'}
                            </DetailsField>
                            <DetailsField label={'Connector format'}>
                                {record.cdr.location.evses[0]?.connectors[0].format ?? '-'}
                            </DetailsField>
                            <DetailsField label={'Connector power type'}>
                                {record.cdr.location.evses[0]?.connectors[0].power_type ?? '-'}
                            </DetailsField>
                            <DetailsField label={'Connector voltage'}>
                                {record.cdr.location.evses[0]?.connectors[0].voltage ?? '-'} V
                            </DetailsField>
                            <DetailsField label={'Connector amperage'}>
                                {record.cdr.location.evses[0]?.connectors[0].amperage ?? '-'} A
                            </DetailsField>
                        </Box>
                        <Box>
                            <DetailsField label={'Période de chargement'}>
                                <Box>Début: {formatDate(record.cdr.charging_periods[0]?.start_date_time)}</Box>
                                <Box>Type: {record.cdr.charging_periods[0]?.dimensions[0].type}</Box>
                                <Box>Volume: {record.cdr.charging_periods[0]?.dimensions[0].volume}</Box>
                            </DetailsField>
                            <DetailsField label={'Tariff'}>
                                {(record.cdr.tariffs && record.cdr.tariffs[0]?.id) ? record.cdr.tariffs[0].id : '-'}
                            </DetailsField>
                            <DetailsField label={'total_cost'}>
                                <TotalCostField source={'cdr.total_cost'}/>
                            </DetailsField>
                            <DetailsField label={'total_energy'}>
                                {tokWh(record.cdr.total_energy)}
                            </DetailsField>
                            <DetailsField label={'total_time'}>
                                {toUnit(record.cdr.total_time, 'h')}
                            </DetailsField>
                            <DetailsField label={'total_parking_time'}>
                                {record.cdr.total_parking_time ? toUnit(record.cdr.total_parking_time, 'h') : '-'}
                            </DetailsField>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
            <Box style={{ display: "flex", flexDirection: "column"}} gap={1}>
                <Card>
                    <CardContent>
                        <Typography variant="h6">Metadata</Typography>
                        <DetailsField label={'ID session'}>
                            {record.id}
                        </DetailsField>
                        <DetailsField label={'eMSP'}>
                            {record.party_id}
                        </DetailsField>
                        <DetailsField label={'Issuer'}>
                            {record.issuer}
                        </DetailsField>
                        <DetailsField label={'Uid'}>
                            {record.uid}
                        </DetailsField>
                        <DetailsField label={'ocppStopReason'}>
                            {record.ocppStopReason ?? '-'}
                        </DetailsField>
                        <DetailsField label={'ocppStopActor'}>
                            {record.ocppStopActor ?? '-'}
                        </DetailsField>
                        <DetailsField label={'httpResponse'}>
                            {record.httpResponse ?? '-'}
                        </DetailsField>
                        <DetailsField label={'ocpiResponseCode'}>
                            {record.ocpiResponseCode ?? '-'}
                        </DetailsField>
                        <DetailsField label={'ocpiResponseMessage'}>
                            {record.ocpiResponseMessage ?? '-'}
                        </DetailsField>
                        <DetailsField label={'ocpiResponseURL'}>
                            {record.ocpiResponseURL ?? '-'}
                        </DetailsField>
                    </CardContent>
                </Card>
                {record.invoice && <Card>
                    <CardContent>
                        <Typography variant="h6">Facturation</Typography>
                        <DetailsField label={'Id details'}>
                            {record.invoice.id}
                        </DetailsField>
                        <DetailsField label={'Période'}>
                            {record.invoice.year}-{record.invoice.month}
                        </DetailsField>
                        {record.invoice.discarded && <DetailsField label={'Non facturé'}>
                            {record.invoice.discarded}
                        </DetailsField>}
                    </CardContent>
                </Card>}
            </Box>
        </Box>
    );
};

export const CdrEdit = () => {
    const { permissions } = usePermissions();

    return (
        <Box padding={2} sx={{
            backgroundColor: "#d5d5d5",
        }}>
            <ShowBase>
                <Box>
                    <Box sx={{display: "flex", justifyContent: "end", gap: 2}} pb={1}>
                        {appConfig.FEATURE_OVERWRITE_CDRS_COST
                            && hasPermission(AppPermissions.CdrOverwriteCost, permissions)
                            && <ButtonOverrideCost/>}
                        <ListButton variant={'contained'} icon={<ChevronLeft/>}/>
                    </Box>
                    <Details/>
                    <CdrOverridesHistory/>
                </Box>
            </ShowBase>
            <Edit>
                <SimpleForm>
                    <TextInput source="notes" multiline={true} sx={{width: "50%", minWidth: 300}}/>
                    <SelectArrayInput source="tags" choices={[
                        {id: 'contestation', name: 'contestation'},
                    ]}/>
                    <TextInput source="id" disabled/>
                </SimpleForm>
            </Edit>
        </Box>
    );
};
