import { useEffect, useState } from "react";
import { useDataProvider, useNotify, useStore } from "react-admin";
import dataProvider from "../../../../providers/dataProvider";
import { CdrStatistics, CdrStatisticsOverTime } from "../../../../providers/dto/CdrStats";
import { DashboardDateOption, translateDateOption } from "../../../data/constants/dashboard-date-option";
import { E55CPalette } from "../../../data/constants/stats-charts-color";
import { CdrDashboardType } from "../../../data/constants/cdr-dashboard-type";
import { CdrPieChart } from "./CdrPieChart";
import { CdrLineChart } from "./CdrLineChart";
import Box from "@mui/material/Box";
import { RemoteContentDisplay } from "../../../components/layout/RemoteContentDisplay";
import {Typography} from "@mui/material";

interface CdrDashboardDetailsProps {
    dashboardType: CdrDashboardType
}

const colorPalette: E55CPalette = new E55CPalette()

const SubSection = ({title, children}: {title: string, children: React.ReactNode}) => {
    return <Box sx={{paddingY: 3}}>
        <Box>
            <Typography variant="h5" sx={{textAlign: "center"}}>{title}</Typography>
        </Box>
        {children}
    </Box>
}

const CdrDashboardDetails : React.FC<CdrDashboardDetailsProps> = ({dashboardType}) => {
    const [isSessionStatsLoading, setSessionStatsLoading] = useState<boolean>(false);
    const [isSessionStatOverTimeLoading, setSessionStatOverTimeLoading] = useState<boolean>(false);
    const [sessionStats, setSessionStats] = useState<CdrStatistics[]>();
    const [sessionStatsOverTime, setSessionStatsOverTime] = useState<CdrStatisticsOverTime[]>();
    const [dateOpt, setDateOpt] = useState<DashboardDateOption>(DashboardDateOption.THIRTY_DAYS);
    const [theme] = useStore("theme")
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();

    useEffect(() => {
        setSessionStatsLoading(true);
        provider.getCdrStats(dashboardType, dateOpt)
        .then(
            (resp) => {
                setSessionStats(resp);
                setSessionStatsLoading(false);
            }
        )
        .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify, dateOpt, dashboardType]);

    useEffect(() => {
        setSessionStatOverTimeLoading(true);
        provider.getCdrOverTimeStats(dashboardType)
        .then(
            (resp) => {
                setSessionStatsOverTime(resp);
                setSessionStatOverTimeLoading(false);
            })
        .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify, dashboardType])

    colorPalette.refresh(sessionStats?.map(el => el.name), theme === "dark", false);

    return (
        <Box>
            <SubSection title={`Total pour période ${translateDateOption(dateOpt)}`}>
                <RemoteContentDisplay isLoading={isSessionStatsLoading} dataArray={sessionStats}>
                    <CdrPieChart sessionStats={sessionStats} chartColors={colorPalette} dateOptHandler={setDateOpt} selectedDate={dateOpt}></CdrPieChart>
                </RemoteContentDisplay>
            </SubSection>

            <SubSection title="Statistiques des 7 derniers jours">
                <RemoteContentDisplay isLoading={isSessionStatOverTimeLoading} dataArray={sessionStatsOverTime}>
                    <CdrLineChart sessionStatsOverTime={sessionStatsOverTime} chartColors={colorPalette}></CdrLineChart>
                </RemoteContentDisplay>
            </SubSection>
        </Box>
    );
}


export default CdrDashboardDetails;
