import {useEffect, useState} from "react";
import {CdrStatistics} from "../../../../providers/dto/CdrStats";
import {DashboardDateOption, getDashboardDateOptionFromString} from "../../../data/constants/dashboard-date-option";
import {useDataProvider, useNotify, useStore} from "react-admin";
import dataProvider from "../../../../providers/dataProvider";
import {CdrDashboardType} from "../../../data/constants/cdr-dashboard-type";
import DashboardSection from "../../../features/dashboard/DashboardSection";
import {RemoteContentDisplay} from "../../../components/layout/RemoteContentDisplay";
import {CdrDashboardTable} from "../../../features/dashboard/session/CdrDashboardTable";
import {E55CPalette} from "../../../data/constants/stats-charts-color";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Box from "@mui/material/Box";
import {DateRangePicker} from "../../../features/dashboard/DateRangePicker";

const colorPalette: E55CPalette = new E55CPalette()

export default function CdrsDetailsStatsTable() {
    const [isSessionStatsLoading, setSessionStatsLoading] = useState<boolean>(false);
    const [sessionStats, setSessionStats] = useState<CdrStatistics[]>();
    const [dateOpt, setDateOpt] = useState<DashboardDateOption>(DashboardDateOption.THIRTY_DAYS);
    const [theme] = useStore("theme")
    const provider = useDataProvider<typeof dataProvider>();
    const notify = useNotify();
    const[dashboardType, setDashboardType] = useState<CdrDashboardType>(CdrDashboardType.PARTY);

    useEffect(() => {
        setSessionStatsLoading(true);
        provider.getCdrStats(dashboardType, dateOpt)
            .then(
                (resp) => {
                    setSessionStats(resp);
                    setSessionStatsLoading(false);
                }
            )
            .catch((err: any) => notify(err.toString(), {type: 'error'}))
    }, [provider, notify, dateOpt, dashboardType]);

    colorPalette.refresh(sessionStats?.map(el => el.name), theme === "dark", false);

    return <DashboardSection title="Tableau détaillé">
        <Box sx={{display: "flex", justifyContent: "center"}}>
            <ToggleButtonGroup value={dashboardType} color={'primary'} sx={{marginY: 3}} exclusive onChange={(event, newValue) => setDashboardType(newValue)}>
                <ToggleButton value={CdrDashboardType.PARTY} aria-label="Party" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                    Par eMSP
                </ToggleButton>
                <ToggleButton value={CdrDashboardType.ISSUERS} aria-label="Issuer" sx={{display: "flex", gap: 1, alignItems: "center"}}>
                    Par issuer
                </ToggleButton>
            </ToggleButtonGroup>
        </Box>
        <RemoteContentDisplay isLoading={isSessionStatsLoading} dataArray={sessionStats}>
            <CdrDashboardTable dashboardType={dashboardType} sessionStats={sessionStats} chartColors={colorPalette} ></CdrDashboardTable>
        </RemoteContentDisplay>

        <Box sx={{display: "flex", justifyContent: "center", mt: 2}}>
            <DateRangePicker
                value={dateOpt}
                handler={(e) => setDateOpt(getDashboardDateOptionFromString(e.currentTarget.getAttribute("value")))}
                dateOptBag={[DashboardDateOption.TODAY, DashboardDateOption.SEVEN_DAYS, DashboardDateOption.THIRTY_DAYS, DashboardDateOption.ONE_HUNDRED_EIGHTY_DAYS, DashboardDateOption.YEAR_TO_DATE, DashboardDateOption.ONE_YEAR]}
            ></DateRangePicker>
        </Box>
    </DashboardSection>
}
